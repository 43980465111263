/* Typography */
body {
    font-family: 'Roboto', sans-serif;
}

h1,
h2 {
    /*color: #333333;*/
    font-weight: 700;
    line-height: 1.2;
}

h1 {
    font-size: 2.5rem;
    margin-bottom: 1.5rem;
}

h2 {
    font-size: 2rem;
    margin-top: 3rem;
    margin-bottom: 1rem;
}

ol,
ul {
    font-size: 1rem;
    list-style-type: none;
    margin: 0;
    padding: 0;
    align-items: flex-start;
}

/* Layout */
/*ion-toolbar {*/
/*    --background: #333333;*/
/*    --color: #ffffff;*/
/*}*/


/* List items */
li {
    display: flex;
    align-items: center;
    justify-content: center;
    /*background-color: #f8f9fa;*/
    border: 1px solid #dee2e6;
    border-radius: 0.5rem;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
    margin-bottom: 1rem;
    padding: 1.5rem;
    font-weight: 600;
}

li::before {
    content: "✨";
    display: inline-block;
    margin-right: 1rem;
}

/* Testimonials */
.testimonial {
    /*background-color: #f8f9fa;*/
    border-radius: 0.5rem;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
    margin-bottom: 1.5rem;
    padding: 1.5rem;
    font-size: 1rem;
    font-style: italic;
    position: relative;
}

.testimonial::before {
    content: "“";
    font-size: 3rem;
    font-weight: 700;
    /*color: #333333;*/
    position: absolute;
    top: -5px;
    left: -5px;
}

.testimonial::after {
    content: "”";
    font-size: 3rem;
    font-weight: 700;
    /*color: #333333;*/
    position: absolute;
    bottom: -5px;
    right: -5px;
}

.testimonial-author {
    display: block;
    font-size: 0.9rem;
    font-style: normal;
    font-weight: 600;
    margin-top: 1rem;
    text-align: right;
}

.container {
    position: relative;
    width: 100%;
}

.img-wrapper {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%; /* 16:9 aspect ratio */
}

.img-wrapper img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    max-width: 90%;
    padding: 20px;
    /*background-color: rgba(0, 0, 0, 0.5);*/
    text-align: center;
}

.overlay h1 {
    color: #fff;
    font-size: 3.5rem;
    font-weight: bold;
    line-height: 1.3;
    /*text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);*/
}

@media (max-width: 768px) {
    .overlay h1 {
        font-size: 2.5rem;
        line-height: 1.2;
    }
}

@media (max-width: 480px) {
    .overlay h1 {
        font-size: 1.5rem;
        line-height: 1.2;
    }
}

@media (max-width: 375px) {
    .overlay h1 {
        font-size: 1.2rem;
        line-height: 1.2;
    }
}

@media (max-width: 320px) {
    .overlay h1 {
        font-size: 1rem;
        line-height: 1.2;
    }
}

.sizeController {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.sizeControllerChild{

    max-width: 1920px;
    display: flex;
    flex-direction: column;
    align-items: center;
}