.logo {
  height: 32px;
}

.logoContainer {
  display: flex;
  margin-left: 32px;
  cursor: pointer;
}

.limited-width {
  max-width: 300px;
}

.circular-image {
  border-radius: 50%;
  clip-path: circle(50% at center);
  max-width: 500px;
}

.copyright{
  padding: 32px;
}
