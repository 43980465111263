/* Hero Section */
.hero {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2rem 0;
    padding-top: 0px;
}

.hero-text {
    flex: 1;
    text-align: center;
    margin-top: 0;
    margin: 16px;
}

.hero-text h1 {
    font-size: 2rem;
    margin-bottom: 1rem;
}

.hero-text h2 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
}



.hero-text p {
    font-size: 1.2rem;
    margin-bottom: 1rem;
}

.hero-image {
    flex: 1;
    text-align: center;
    margin: 16px;
}

/* Form Section */
.form-section {
    background-color: #f4f4f4;
    padding: 2rem;
    text-align: center;
}

form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

input {
    padding: 0.5rem;
}

button {
    background-color: #333;
    color: #fff;
    padding: 0.5rem;
    cursor: pointer;
}

button:hover {
    background-color: #555;
}

.guarantee {
    font-style: italic;
    margin-top: 1rem;
}

/* Benefits Section */
.benefits {
    display: flex;
    justify-content: center;
    gap: 1rem;
    padding: 2rem 2rem;
}

.benefit {
    flex: 1;
    text-align: center;
}

.benefit h3 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
}

.benefit p {
    font-size: 1.1rem;
    margin-bottom: 1rem;
}

.price {
    font-size: 1.5rem;
    color: #E63946;
    margin-bottom: 1rem;
}

@media screen and (max-width: 768px) {
    .hero {
        flex-direction: column;
    }

    .price {
        font-size: 1.15rem;
    }

    .hero-text h1 {
        font-size: 1.5rem;
    }

    .hero-text h2 {
        font-size: 1.2rem;
    }



    .hero-text p {
        font-size: 1rem;
    }

    .benefits {
        flex-direction: column;
    }

    .benefit {
        margin-bottom: 2rem;
    }
}

@media screen and (max-width: 480px) {
    nav {
        flex-direction: column;
        text-align: center;
        gap: 0.5rem;
    }

    .price {
        font-size: 1.15rem;
    }

    .hero {
        flex-direction: column-reverse;
    }

    .hero-text h1 {
        font-size: 1.2rem;
    }

    .hero-text p {
        font-size: 0.9rem;
    }

    .form-section {
        padding: 1rem;
    }

    .benefits {
        flex-direction: column;
    }

    .benefit {
        margin-bottom: 2rem;
    }
}